/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - QueryClient.ts
 */

import { QueryClient } from '@tanstack/react-query';
import settings        from 'library/settings';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime           : settings.models['default-stale-time'],
      refetchOnWindowFocus: false,
      refetchOnReconnect  : false,
      refetchInterval     : settings.models['auto-refetch-time-ms'],

      retry: (failureCount: number, error: any) => {
        console.log(failureCount, error?.code);
        return !('code' in error && error.code === 404);
      },
    },
  },
});
